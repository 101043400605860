<template>
  <v-card id="form_container">
    <div id="form_container_1">
      <v-card
        id="form"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 30px;
        "
      >
        <div class="banner" style="width: 100%; height: 6rem; display: flex">
          <v-card
            class="job_info"
            style="
              display: flex;
              box-shadow: none;
              width: 40%;
              height: 100% !important;
              align-items: center;
              justify-content: center;
            "
          >
            <v-card
              style="
                height: 100% !important;
                width: 100%;
                box-shadow: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #0263ad;
                border-radius: 0;
                background-color: #026fc0;
                font-size: 2.6rem;
                font-weight: bolder;
                color: white;
              "
              >JOB ID:</v-card
            >
            <v-card
              style="
                height: 100% !important;
                width: 100%;
                box-shadow: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #0263ad;
                border-radius: 0;
                background-color: white;
                font-size: 2.6rem;
              "
            ></v-card>
          </v-card>
          <img
            src="https://agedcareonline.com.au/uploads/netbookings/id5321/bethanielogo.jpg"
            alt="Vue"
            style="width: 200px; margin-left: 20%"
          />
        </div>
        <div
          class="title"
          style="
            width: 100%;
            display: flex;
            height: 5rem;
            justify-content: center;
            align-items: center;
            font-size: 2rem !important;
            font-weight: bolder;
            margin-top: 20px;
          "
        >
          Bethanie Aged Care Internal Maintenance Request
        </div>
        <v-container class="container" width="100%">
          <v-row class="special_row" no-gutters style="margin-top: 56px">
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Staff Member Details
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Staff Name:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.staff.name"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Staff Email:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.staff.email"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="special_row" no-gutters style="margin-top: 56px">
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Request Details
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Date of Request:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.request.date"
                color="black"
                class="text_field"
                type="date"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Facility Location:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.request.location"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Area issue is occurring in:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.request.occuring"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Is this repair and urgent matter:
              </v-card>
            </v-col>
            <v-col md="7">
              <div style="display: flex; align-items: center">
                <v-checkbox
                  v-model="formContent.request.isRepair"
                  :true-value="true"
                  :false-value="false"
                  light
                  label=""
                  class="chckbox"
                  color="black"
                ></v-checkbox>
                <p style="margin: 0">
                  {{ formContent.request.isRepair ? "YES" : "NO" }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Does this request directly impact a resident:
              </v-card>
            </v-col>
            <v-col md="7">
              <div style="display: flex; align-items: center">
                <v-checkbox
                  v-model="formContent.request.isImpact"
                  :true-value="true"
                  :false-value="false"
                  light
                  label=""
                  class="chckbox"
                  color="black"
                ></v-checkbox>
                <p style="margin: 0">
                  {{ formContent.request.isImpact ? "YES" : "NO" }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Request Details:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.request.detail"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-expansion-panels multiple v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header class="Header"
                  >Latest Update</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-container
                    class="last_update"
                    style="
                      width: 100%;
                      background-color: #f2f2f2;
                      margin: 56px 0;
                    "
                  >
                    <v-row no-gutters>
                      <v-col cols="13" md="5">
                        <v-card
                          class="pa-2"
                          outlined
                          tile
                          style="
                            border: none;
                            font-weight: bolder !important;
                            background-color: inherit;
                            font-size: 1.25rem;
                          "
                        >
                          Name of Maintenance Staff:
                        </v-card>
                      </v-col>
                      <v-col md="7">
                        <v-text-field
                          :readonly="true"
                          v-model="formContent.lastUpdate.staff.name"
                          color="black"
                          class="text_field"
                          style=""
                          label=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="13" md="5">
                        <v-card
                          class="pa-2"
                          outlined
                          tile
                          style="
                            border: none;
                            font-weight: bolder !important;
                            background-color: inherit;
                            font-size: 1.25rem;
                          "
                        >
                          Maintenance Staff Email:
                        </v-card>
                      </v-col>
                      <v-col md="7">
                        <v-text-field
                          :readonly="true"
                          v-model="formContent.lastUpdate.staff.email"
                          color="black"
                          class="text_field"
                          label=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="13" md="5">
                        <v-card
                          class="pa-2"
                          outlined
                          tile
                          style="
                            border: none;
                            font-weight: bolder !important;
                            background-color: inherit;
                            font-size: 1.25rem;
                          "
                        >
                          Date of attendance:
                        </v-card>
                      </v-col>
                      <v-col md="7">
                        <v-text-field
                          :readonly="true"
                          v-model="formContent.lastUpdate.date"
                          color="black"
                          class="text_field"
                          label=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="13" md="5">
                        <v-card
                          class="pa-2"
                          outlined
                          tile
                          style="
                            border: none;
                            font-weight: bolder !important;
                            background-color: inherit;
                            font-size: 1.25rem;
                          "
                        >
                          Actions completed:
                        </v-card>
                      </v-col>
                      <v-col md="7">
                        <v-text-field
                          :readonly="true"
                          v-model="formContent.lastUpdate.action"
                          color="black"
                          class="text_field"
                          label=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="13" md="5">
                        <v-card
                          class="pa-2"
                          outlined
                          tile
                          style="
                            border: none;
                            font-weight: bolder !important;
                            background-color: inherit;
                            font-size: 1.25rem;
                          "
                        >
                          Has the Request been completed in full:
                        </v-card>
                      </v-col>
                      <v-col md="7">
                        <v-text-field
                          :readonly="true"
                          v-model="formContent.lastUpdate.completed"
                          color="black"
                          class="text_field"
                          label=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="13" md="5">
                        <v-card
                          class="pa-2"
                          outlined
                          style="
                            border: none;
                            font-weight: bolder !important;
                            background-color: inherit;
                            font-size: 1.25rem;
                          "
                        >
                          Any actions still required:
                        </v-card>
                      </v-col>
                      <v-col md="7">
                        <v-text-field
                          :readonly="true"
                          v-model="formContent.lastUpdate.anyAction"
                          color="black"
                          class="text_field"
                          label=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row no-gutters style="margin-top: 20px">
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Job Audit
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.jobAudit"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card
        class="group_button"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding-bottom: 50px;
          background-color: white;
          box-shadow: none;
        "
      >
        <v-btn style="width: 20%" class="button_submit" @click="print">
          SUBMIT REQUEST
        </v-btn>
      </v-card>
    </div>
    <div :style="cssLayout">
      <Loading
        :color="'#006680'"
        :secondaryColor="'#006680'"
        :size="'70rem'"
      ></Loading>
    </div>
    <div style="display: none">
      <v-card
        id="form1"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 30px;
        "
      >
        <div class="banner" style="width: 100%; height: 6rem; display: flex">
          <v-card
            class="job_info"
            style="
              display: flex;
              box-shadow: none;
              width: 40%;
              height: 100% !important;
              align-items: center;
              justify-content: center;
            "
          >
            <v-card
              style="
                height: 100% !important;
                width: 100%;
                box-shadow: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #0263ad;
                border-radius: 0;
                background-color: #026fc0;
                font-size: 2.6rem;
                font-weight: bolder;
                color: white;
              "
              >JOB ID:</v-card
            >
            <v-card
              style="
                height: 100% !important;
                width: 100%;
                box-shadow: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #0263ad;
                border-radius: 0;
                background-color: white;
                font-size: 2.6rem;
              "
            ></v-card>
          </v-card>
          <img
            src="https://agedcareonline.com.au/uploads/netbookings/id5321/bethanielogo.jpg"
            alt="Vue"
            style="width: 60%"
          />
        </div>
        <div
          class="title"
          style="
            width: 100%;
            display: flex;
            height: 5rem;
            justify-content: center;
            align-items: center;
            font-size: 2rem !important;
            font-weight: bolder;
            margin-top: 20px;
          "
        >
          Bethanie Aged Care Internal Maintenance Request
        </div>
        <v-container class="container" width="100%">
          <v-row class="special_row" no-gutters style="margin-top: 56px">
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Staff Member Details
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Staff Name:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.staff.name"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Staff Email:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.staff.email"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="special_row" no-gutters style="margin-top: 56px">
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Request Details
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Date of Request:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.request.date"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Facility Location:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.request.location"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Area issue is occurring in:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.request.occuring"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Is this repair and urgent matter:
              </v-card>
            </v-col>
            <v-col md="7">
              <div style="display: flex; align-items: center">
                <v-checkbox
                  v-model="formContent.request.isRepair"
                  :true-value="true"
                  :false-value="false"
                  light
                  label=""
                  class="chckbox"
                  color="black"
                ></v-checkbox>
                <p style="margin: 0">
                  {{ formContent.request.isRepair ? "YES" : "NO" }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Does this request directly impact a resident:
              </v-card>
            </v-col>
            <v-col md="7">
              <div style="display: flex; align-items: center">
                <v-checkbox
                  v-model="formContent.request.isImpact"
                  :true-value="true"
                  :false-value="false"
                  light
                  label=""
                  class="chckbox"
                  color="black"
                ></v-checkbox>
                <p style="margin: 0">
                  {{ formContent.request.isImpact ? "YES" : "NO" }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Request Details:
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.request.detail"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-container
              class="last_update"
              style="width: 100%; background-color: #f2f2f2; margin: 56px 0"
            >
              <v-row no-gutters>
                <v-col cols="13" md="5">
                  <v-card
                    class="pa-2"
                    outlined
                    tile
                    style="
                      border: none;
                      font-weight: bolder !important;
                      background-color: inherit;
                      font-size: 1.25rem;
                    "
                  >
                    Latest Update
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="13" md="5">
                  <v-card
                    class="pa-2"
                    outlined
                    tile
                    style="
                      border: none;
                      font-weight: bolder !important;
                      background-color: inherit;
                      font-size: 1.25rem;
                    "
                  >
                    Name of Maintenance Staff:
                  </v-card>
                </v-col>
                <v-col md="7">
                  <v-text-field
                    :readonly="true"
                    v-model="formContent.lastUpdate.staff.name"
                    color="black"
                    class="text_field"
                    style=""
                    label=""
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="13" md="5">
                  <v-card
                    class="pa-2"
                    outlined
                    tile
                    style="
                      border: none;
                      font-weight: bolder !important;
                      background-color: inherit;
                      font-size: 1.25rem;
                    "
                  >
                    Maintenance Staff Email:
                  </v-card>
                </v-col>
                <v-col md="7">
                  <v-text-field
                    :readonly="true"
                    v-model="formContent.lastUpdate.staff.email"
                    color="black"
                    class="text_field"
                    label=""
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="13" md="5">
                  <v-card
                    class="pa-2"
                    outlined
                    tile
                    style="
                      border: none;
                      font-weight: bolder !important;
                      background-color: inherit;
                      font-size: 1.25rem;
                    "
                  >
                    Date of attendance:
                  </v-card>
                </v-col>
                <v-col md="7">
                  <v-text-field
                    :readonly="true"
                    v-model="formContent.lastUpdate.date"
                    color="black"
                    class="text_field"
                    label=""
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="13" md="5">
                  <v-card
                    class="pa-2"
                    outlined
                    tile
                    style="
                      border: none;
                      font-weight: bolder !important;
                      background-color: inherit;
                      font-size: 1.25rem;
                    "
                  >
                    Actions completed:
                  </v-card>
                </v-col>
                <v-col md="7">
                  <v-text-field
                    :readonly="true"
                    v-model="formContent.lastUpdate.action"
                    color="black"
                    class="text_field"
                    label=""
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="13" md="5">
                  <v-card
                    class="pa-2"
                    outlined
                    tile
                    style="
                      border: none;
                      font-weight: bolder !important;
                      background-color: inherit;
                      font-size: 1.25rem;
                    "
                  >
                    Has the Request been completed in full:
                  </v-card>
                </v-col>
                <v-col md="7">
                  <v-text-field
                    :readonly="true"
                    v-model="formContent.lastUpdate.completed"
                    color="black"
                    class="text_field"
                    label=""
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="13" md="5">
                  <v-card
                    class="pa-2"
                    outlined
                    style="
                      border: none;
                      font-weight: bolder !important;
                      background-color: inherit;
                      font-size: 1.25rem;
                    "
                  >
                    Any actions still required:
                  </v-card>
                </v-col>
                <v-col md="7">
                  <v-text-field
                    :readonly="true"
                    v-model="formContent.lastUpdate.anyAction"
                    color="black"
                    class="text_field"
                    label=""
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <v-row no-gutters>
            <v-col cols="13" md="5">
              <v-card
                class="pa-2"
                outlined
                tile
                style="
                  border: none;
                  font-weight: bolder !important;
                  font-size: 1.25rem;
                "
              >
                Job Audit
              </v-card>
            </v-col>
            <v-col md="7">
              <v-text-field
                v-model="formContent.jobAudit"
                color="black"
                class="text_field"
                label=""
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import html2pdf from "html2pdf.js";
import Loading from "../../loading/Loading.vue";
import { callMethodFunc } from "../../../store/Method/index";
export default {
  components: { Loading },
  mounted() {
    setTimeout(() => {
      this.display = "none";
    }, 30);
  },
  data() {
    return {
      panel: [1],
      display: "flex",
      formContent: {
        staff: {
          name: "", //StaffName_c
          email: "", //StaffEmail_c
        },
        request: {
          // date: "2023-06-10", //AgeCare_ReqDate_c
          date: `${new Date().getFullYear().toString()}-${(
            new Date().getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${new Date()
            .getDate()
            .toString()
            .padStart(2, "0")}`, //AgeCare_ReqDate_c
          location: "", //AgeCare_FacilityLoc_c
          occuring: "", //CaseTopics
          isRepair: true, //Age_IsUrgency_c
          isImpact: true, //Age_IsDirectImpaction_c
          detail: "", //IssueText,IssueSummary
        },
        lastUpdate: {
          staff: {
            name: "", //StaffName_c
            email: "", //StaffEmail_c
          },
          date: "", //AgeCare_ReqDate_c
          action: "", //CaseTopics
          completed: "", //Age_IsCompleted_c
          anyAction: "",
        },
        jobAudit: "",
      },
    };
  },
  computed: {
    cssLoader() {
      return {
        "--color": "#006680",
        color: "#006680",
        "--scale": 1,
      };
    },
    cssLayout() {
      return {
        position: "fixed",
        display: this.display,
        height: "100vh",
        "background-color": "rgba(153, 151, 151, 0.5)",
        "justify-content": "center",
        "align-items": "center",
        width: "100%",
        "z-index": 999999,
      };
    },
  },
  watch: {},
  methods: {
    async print() {
      let bo = "Erp.BO.HelpDesk";
      let method1 = "GetNewHDCase";
      let body1 = {
        ds: {},
      };
      await callMethodFunc(bo, method1, body1).then(async (res1) => {
        if (res1 && res1.message == "error") {
          alert("res1" + res1.error);
        } else {
          let returnObj = JSON.parse(JSON.parse(res1.data).data).parameters.ds
            .HDCase[0];
          let method2 = "Update";
          let date = new Date(this.formContent.request.date);
          let day = date.getDate().toString().padStart(2, "0"),
            month = (date.getMonth() + 1).toString().padStart(2, "0"),
            year = date.getFullYear().toString();
          let body2 = {
            ds: {
              HDCase: [
                {
                  ...returnObj,
                  StaffName_c: this.formContent.staff.name,
                  StaffEmail_c: this.formContent.staff.email,
                  AgeCare_ReqDate_c: `${year}-${month}-${day}T00:00:00+00:00`,
                  AgeCare_FacilityLoc_c: this.formContent.request.location,
                  ResolutionText: this.formContent.request.occuring,
                  Age_IsUrgency_c: this.formContent.request.isRepair,
                  Age_IsDirectImpaction_c: this.formContent.request.isImpact,
                  IssueSummary: "Internal Request",
                  IssueText: this.formContent.request.detail,
                  Age_IsCompleted_c: false,
                  RowMod: "A",
                },
              ],
            },
          };
          await callMethodFunc(bo, method2, body2).then((res2) => {
            if (res2 && res2.message == "error") {
              alert("res2" + res2.error);
            } else {
              returnObj = JSON.parse(JSON.parse(res2.data).data).parameters.ds
                .HDCase[0];
              html2pdf().set({
                pagebreak: { mode: ["avoid-all", "css", "legacy"] },
              });
              html2pdf(document.getElementById("form1"), {
                margin: 1,
                filename: "MaintenanceRequest.pdf",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/standard.scss";

#form_container {
  position: relative;
  width: 100%;
  min-height: 100vh;

  height: fit-content;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;

  #form_container_1 {
    width: 100%;
  }

  // #form {
  //   width: 90%;
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  //   padding: 30px;

  //   .banner {
  //     width: 100%;
  //     height: 6rem;
  //     display: flex;

  //     img {
  //       width: 60%;
  //     }

  //     .job_info {
  //       display: flex;
  //       box-shadow: none;
  //       width: 40%;
  //       height: 100% !important;
  //       align-items: center;
  //       justify-content: center;

  //       .v-card {
  //         height: 100% !important;
  //         width: 100%;
  //         box-shadow: none;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         border: 2px solid #0263ad;
  //         border-radius: 0;
  //       }

  //       :nth-child(odd) {
  //         background-color: #026fc0;
  //         font-size: 2.6rem;
  //         font-weight: bolder;
  //         color: white;
  //       }

  //       :nth-child(even) {
  //         background-color: white;
  //         font-size: 2.6rem;
  //       }
  //     }
  //   }

  //   .title {
  //     width: 100%;
  //     display: flex;
  //     height: 5rem;
  //     justify-content: center;
  //     align-items: center;
  //     font-size: 2.5rem !important;
  //     font-weight: bolder;
  //     margin-top: 20px;
  //   }

  //   .subtitle {
  //     width: 100%;
  //     display: flex;
  //     height: 3rem;
  //     justify-content: center;
  //     align-items: center;
  //     font-size: 1.25rem !important;
  //   }

  //   .container {
  //     width: 100%;

  //     .special_row {
  //       margin-top: 56px;
  //     }

  //     .row {
  //       :nth-child(odd) {
  //         .v-card {
  //           border: none;
  //           font-weight: bolder !important;
  //           font-size: 1.25rem;
  //         }
  //       }
  //     }

  //     .last_update {
  //       background-color: #f2f2f2;
  //       margin: 56px 0;

  //       .v-card {
  //         border: none;
  //         background-color: inherit;
  //         font-weight: bolder !important;
  //         font-size: 1.25rem;

  //         .text_field {
  //           height: 100%;
  //           border: none;
  //         }
  //       }
  //     }
  //   }

  // .group_button {
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;

  //     .button_submit {
  //       width: 20%;
  //     }
  //   }
  // }
}

.preloader {
  position: absolute;
  width: 125px;
  height: 125px;
  margin: auto;
  transform: scale(1);

  span {
    position: absolute;
    background-color: transparent;
    height: 22px;
    width: 22px;
    border-radius: 12px;
    animation-name: f_fadeG;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }

  .rot-1 {
    left: 0;
    top: 51px;
    animation-delay: 0.45s;
  }

  .rot-2 {
    left: 15px;
    top: 15px;
    animation-delay: 0.6s;
  }

  .rot-3 {
    left: 51px;
    top: 0;
    animation-delay: 0.75s;
  }

  .rot-4 {
    right: 15px;
    top: 15px;
    animation-delay: 0.9s;
  }

  .rot-5 {
    right: 0;
    top: 51px;
    animation-delay: 1.05s;
  }

  .rot-6 {
    right: 15px;
    bottom: 15px;
    animation-delay: 1.2s;
  }

  .rot-7 {
    left: 51px;
    bottom: 0;
    animation-delay: 1.35s;
  }

  .rot-8 {
    left: 15px;
    bottom: 15px;
    animation-delay: 1.5s;
  }

  @keyframes f_fadeG {
    0% {
      background-color: var(--color);
    }

    100% {
      background-color: transparent;
    }
  }
}
</style>
